//import "primereact/resources/primereact.min.css";
import "../src/assets/css/responsive.css";
import "./App.css";
import InstallPage from "./Components/Common/InstallPage";


function App() {
  return <InstallPage />;
}

export default App;
